.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.Card {
  display: flex;
  flex-direction: column;
  margin: 5vh 5vw 5vh 5vw;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  height: 75vh;
  width: 60vw;
  align-items: center;
  justify-content: center;
}

.TryMe {
  width: 4vw;
  margin-top: 1vh;
}

.App-controls {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.App-image {
  width:auto;
  max-height: 70vh;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-around;
}

.App-graph{
  width: 50vw;
  height: 40vh;
}

.Row-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 40px;

}

.Row {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
}

.Row-text{
  width: 50%;
  font-size: 15px;
  padding: 0;
  margin-top: 1px;
  margin-bottom: 1px;
}

.Row-value{
  width: 20%;
  font-size: 15px;
  padding: 0;
  margin-top: 1px;
  margin-bottom: 1px;
}

.Row-button {
  color: aquamarine;
  width: 10%;
  padding: 0;
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 15px;
  font-weight: bolder;
}
.tooltip-container {
  border-radius: var(--border-radius);
  box-shadow: var(--card-box-shadow);
  padding: 20px;
  background-color: var(--background-color);
  z-index: 100000;
  font-size: var(--data-font-size);
  overflow: visible;
}

/* FOR MOBILE */
@media (max-width: 800px) {

  .Row-text{
    font-size: 10px;
  }
  .Row-value{
    font-size: 10px;
  }

  .Card {
    margin: 5px;
  }

  .App-image {
    width: 98%;
  }
}